import React from 'react';
import Layout from '../components/layout';
import ChangeAddress from '../components/change-address';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { TOAST_TYPE, useToast } from '../ui/toast';

const DeliveryNotAvailablePage = (): JSX.Element => {
  const { showToast } = useToast();

  return (
    <Layout header menuState="delivery" requireAuth>
      <ChangeAddress onAddressConfirmation={(isAvailable): void => {
          if (isAvailable) {
            navigate('/cart');
          } else {
            showToast(<FormattedMessage id="error.locationNotAvailable" />, {
              type: TOAST_TYPE.WARNING,
            });
          }
        }}
      />
    </Layout>
  );
};

export default DeliveryNotAvailablePage;
